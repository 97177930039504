* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #f99f36;
}
.main-ins {
  display: flex;
  justify-content: center;
}
.container-ins {
  width: 80%;
  padding: 40px;
}
img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .container-ins {
    width: 100%;
    padding: 30px;
  }
  .logo-ins p {
    font-size: 16px !important;
  }
  .logo-ins h1 {
    font-size: 30px !important;
  }
}
@media (max-width: 576px) {
  .container-ins {
    width: 100%;
    padding: 20px;
  }
  .logo-ins {
    /* display: grid !important; */
    background-color: #000;
    justify-content: center !important;
    padding: 10px;
    align-items: center;
  }
  .logo-ins img {
    width: 70%;
  }
  .logo-ins-img {
    display: flex;
    /* justify-content: center !important; */
    order: 2 !important;
  }
  .logo-ins p {
    font-size: 14px !important;
    /* text-align: center !important; */
  }
  .logo-ins h1 {
    font-size: 30px !important;
    /* text-align: center !important; */
  }
}
.logo-ins {
  display: flex;
  background-color: #000;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}
.logo-ins-img {
  display: flex;
  justify-content: end;
}
.logo-ins h1 {
  color: var(--main-color) !important;
  font-size: 40px;
}
.logo-ins p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.content-ins{
    margin-top: 0px;
}

.para-ins{
    font-size: 16px;
    margin: 10px 0;
}
.gold-series-ins h4 {
    margin-top: 20px;
}
.advangate-ins h3 {
    margin-top: 20px;

}
.advangate-ins ul li {
    margin-top: 20px;
}
.advangate-ins ul li h4 {
    margin-top: 10px;
    margin-left: 0px;
}
.advangate-ins ul li p {
    margin: 10px 0;

}
.address-ins h1 {
    margin-top: 20px;
}

.address-ins h3 {
    margin-top: 20px;
    color: var(--main-color);
    text-decoration: underline;
}
.address-ins h4 {
  margin-top: 20px;
}
.address-ins p {
    margin-top: 10px;
}
.follow-ins {
    margin-top: 40px;
}
.socail-links-ins
{
    display: flex;
}
.socail-links-ins img{
    width: 3%;
    margin-top: 10px;
    margin-left: 20px;
} 